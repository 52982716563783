exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-company-tsx": () => import("./../../../src/pages/en/company.tsx" /* webpackChunkName: "component---src-pages-en-company-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-news-microcms-news-en-news-en-id-tsx": () => import("./../../../src/pages/en/news/{microcmsNewsEn.newsEnId}.tsx" /* webpackChunkName: "component---src-pages-en-news-microcms-news-en-news-en-id-tsx" */),
  "component---src-pages-en-news-tsx": () => import("./../../../src/pages/en/news.tsx" /* webpackChunkName: "component---src-pages-en-news-tsx" */),
  "component---src-pages-en-portfolio-tsx": () => import("./../../../src/pages/en/portfolio.tsx" /* webpackChunkName: "component---src-pages-en-portfolio-tsx" */),
  "component---src-pages-en-team-tsx": () => import("./../../../src/pages/en/team.tsx" /* webpackChunkName: "component---src-pages-en-team-tsx" */),
  "component---src-pages-en-value-tsx": () => import("./../../../src/pages/en/value.tsx" /* webpackChunkName: "component---src-pages-en-value-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-microcms-news-news-id-tsx": () => import("./../../../src/pages/news/{microcmsNews.newsId}.tsx" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-value-tsx": () => import("./../../../src/pages/value.tsx" /* webpackChunkName: "component---src-pages-value-tsx" */)
}

